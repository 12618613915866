define("discourse/plugins/discourse-ai/discourse/components/ai-helper-button-group", ["exports", "discourse/components/d-button", "discourse/helpers/concat-class", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _dButton, _concatClass, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AiHelperButtonGroup = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <ul class="ai-helper-button-group" ...attributes>
      {{#each @buttons as |button|}}
        <li>
          <DButton
            @icon={{button.icon}}
            @label={{button.label}}
            @action={{button.action}}
            class={{concatClass "btn-flat" button.classes}}
          />
        </li>
      {{/each}}
    </ul>
  
  */
  {
    "id": "yEiSz1z6",
    "block": "[[[1,\"\\n  \"],[11,\"ul\"],[24,0,\"ai-helper-button-group\"],[17,1],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,2]],null]],null],null,[[[1,\"      \"],[10,\"li\"],[12],[1,\"\\n        \"],[8,[32,0],[[16,0,[28,[32,1],[\"btn-flat\",[30,3,[\"classes\"]]],null]]],[[\"@icon\",\"@label\",\"@action\"],[[30,3,[\"icon\"]],[30,3,[\"label\"]],[30,3,[\"action\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[3]],null],[1,\"  \"],[13],[1,\"\\n\"]],[\"&attrs\",\"@buttons\",\"button\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/ai-helper-button-group.js",
    "scope": () => [_dButton.default, _concatClass.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = AiHelperButtonGroup;
});