define("discourse/plugins/discourse-ai/discourse/services/ai-composer-helper", ["exports", "@glimmer/tracking", "@ember/service"], function (_exports, _tracking, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiComposerHelper extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "menuState", [_tracking.tracked], function () {
      return this.MENU_STATES.triggers;
    }))();
    #menuState = (() => (dt7948.i(this, "menuState"), void 0))();
    MENU_STATES = {
      triggers: "TRIGGERS",
      options: "OPTIONS",
      resets: "RESETS",
      loading: "LOADING",
      review: "REVIEW"
    };
  }
  _exports.default = AiComposerHelper;
});