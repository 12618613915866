define("discourse/plugins/discourse-ai/discourse/components/modal/diff-modal", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/runloop", "@ember/service", "@ember/template", "discourse/components/cook-text", "discourse/components/d-button", "discourse/components/d-modal", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _runloop, _service, _template2, _cookText, _dButton, _dModal, _ajax, _ajaxError, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ModalDiffModal extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return false;
    }))();
    #loading = (() => (dt7948.i(this, "loading"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "diff", [_tracking.tracked]))();
    #diff = (() => (dt7948.i(this, "diff"), void 0))();
    suggestion = "";
    PROOFREAD_ID = (() => -303)();
    constructor() {
      super(...arguments);
      this.diff = this.args.model.diff;
      (0, _runloop.next)(() => {
        if (this.args.model.toolbarEvent) {
          this.loadDiff();
        }
      });
    }
    async loadDiff() {
      this.loading = true;
      try {
        const suggestion1 = await (0, _ajax.ajax)("/discourse-ai/ai-helper/suggest", {
          method: "POST",
          data: {
            mode: this.PROOFREAD_ID,
            text: this.selectedText,
            force_default_locale: true
          }
        });
        this.diff = suggestion1.diff;
        this.suggestion = suggestion1.suggestions[0];
      } catch (e1) {
        (0, _ajaxError.popupAjaxError)(e1);
      } finally {
        this.loading = false;
      }
    }
    get selectedText() {
      const selected1 = this.args.model.toolbarEvent.selected;
      if (selected1.value === "") {
        return selected1.pre + selected1.post;
      }
      return selected1.value;
    }
    triggerConfirmChanges() {
      this.args.closeModal();
      if (this.args.model.confirm) {
        this.args.model.confirm();
      }
      if (this.args.model.toolbarEvent && this.suggestion) {
        this.args.model.toolbarEvent.replaceText(this.selectedText, this.suggestion);
      }
    }
    static #_4 = (() => dt7948.n(this.prototype, "triggerConfirmChanges", [_object.action]))();
    triggerRevertChanges() {
      this.args.model.revert();
      this.args.closeModal();
    }
    static #_5 = (() => dt7948.n(this.prototype, "triggerRevertChanges", [_object.action]))();
    static #_6 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DModal
          class="composer-ai-helper-modal"
          @title={{i18n "discourse_ai.ai_helper.context_menu.changes"}}
          @closeModal={{@closeModal}}
        >
          <:body>
            {{#if this.loading}}
              <div class="composer-ai-helper-modal__loading">
                <CookText @rawText={{this.selectedText}} />
              </div>
            {{else}}
              {{#if this.diff}}
                {{htmlSafe this.diff}}
              {{else}}
                <div class="composer-ai-helper-modal__old-value">
                  {{@model.oldValue}}
                </div>
    
                <div class="composer-ai-helper-modal__new-value">
                  {{@model.newValue}}
                </div>
              {{/if}}
            {{/if}}
          </:body>
    
          <:footer>
            {{#if this.loading}}
              <DButton
                class="btn-primary"
                @label="discourse_ai.ai_helper.context_menu.loading"
                @disabled={{true}}
              />
            {{else}}
              <DButton
                class="btn-primary confirm"
                @action={{this.triggerConfirmChanges}}
                @label="discourse_ai.ai_helper.context_menu.confirm"
              />
              {{#if @model.revert}}
                <DButton
                  class="btn-flat revert"
                  @action={{this.triggerRevertChanges}}
                  @label="discourse_ai.ai_helper.context_menu.revert"
                />
              {{/if}}
            {{/if}}
          </:footer>
        </DModal>
      
    */
    {
      "id": "CuinG+Qy",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"composer-ai-helper-modal\"]],[[\"@title\",\"@closeModal\"],[[28,[32,1],[\"discourse_ai.ai_helper.context_menu.changes\"],null],[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n\"],[41,[30,0,[\"loading\"]],[[[1,\"          \"],[10,0],[14,0,\"composer-ai-helper-modal__loading\"],[12],[1,\"\\n            \"],[8,[32,2],null,[[\"@rawText\"],[[30,0,[\"selectedText\"]]]],null],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"diff\"]],[[[1,\"            \"],[1,[28,[32,3],[[30,0,[\"diff\"]]],null]],[1,\"\\n\"]],[]],[[[1,\"            \"],[10,0],[14,0,\"composer-ai-helper-modal__old-value\"],[12],[1,\"\\n              \"],[1,[30,2,[\"oldValue\"]]],[1,\"\\n            \"],[13],[1,\"\\n\\n            \"],[10,0],[14,0,\"composer-ai-helper-modal__new-value\"],[12],[1,\"\\n              \"],[1,[30,2,[\"newValue\"]]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]]]],[]]],[1,\"      \"]],[]],[[[1,\"\\n\"],[41,[30,0,[\"loading\"]],[[[1,\"          \"],[8,[32,4],[[24,0,\"btn-primary\"]],[[\"@label\",\"@disabled\"],[\"discourse_ai.ai_helper.context_menu.loading\",true]],null],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[32,4],[[24,0,\"btn-primary confirm\"]],[[\"@action\",\"@label\"],[[30,0,[\"triggerConfirmChanges\"]],\"discourse_ai.ai_helper.context_menu.confirm\"]],null],[1,\"\\n\"],[41,[30,2,[\"revert\"]],[[[1,\"            \"],[8,[32,4],[[24,0,\"btn-flat revert\"]],[[\"@action\",\"@label\"],[[30,0,[\"triggerRevertChanges\"]],\"discourse_ai.ai_helper.context_menu.revert\"]],null],[1,\"\\n\"]],[]],null]],[]]],[1,\"      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\",\"@model\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/modal/diff-modal.js",
      "scope": () => [_dModal.default, _i18n.default, _cookText.default, _template2.htmlSafe, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ModalDiffModal;
});